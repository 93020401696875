import { template as template_a319d4439ae24c64bae3aa063bf161ee } from "@ember/template-compiler";
const FKControlMenuContainer = template_a319d4439ae24c64bae3aa063bf161ee(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
