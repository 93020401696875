import { template as template_97f30e57ecf042d2b95008a260f458dc } from "@ember/template-compiler";
const FKLabel = template_97f30e57ecf042d2b95008a260f458dc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
